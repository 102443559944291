import React from "react";

export const Agende = (props) => {
  const openInNewTab = url => {
    window.dataLayer.push({'event': 'clickContato'})
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div id="agende" className="agende">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/team/agende.jpg" className="img-agende" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text agende-section">
              <h2>Dr. Flávio Arruda</h2>
              <p className="agende-p">CRM PE 19365 | RQE Cirurgião plástico Nº: 3871</p>
              <br />
              <button type="button" class="btn btn-danger btn-lg" style={{textTransform:"uppercase"}} 
              onClick={() => openInNewTab('https://wa.me/5581994184533')}>
                <span> Agende sua consulta</span>
                <i className="fa fa-whatsapp" style={{padding: 0, marginLeft:'4px', background: 'none', fontSize: '20px', height:'20px', width:'20px'}}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
