import React, { useCallback, useState } from "react";

import ImageViewer from 'react-simple-image-viewer';

export const Gallery = (props) => {

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  

  const openImageViewer = useCallback((index) => {
    console.log(index)
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Consultório</h2>
          <p>
            Nosso consultório está localizado no Empresarial Charles Darwin - R. Sen. José Henrique, 231 sala 601 - Ilha do Leite
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                <img
                src={ d.largeImage }
                onClick={ () => openImageViewer(i) }
                width="300"
                key={ i }
                style={{ margin: '2px', cursor: 'pointer' }}
                alt=""
              />
                ))
              : "Loading..."}
            {isViewerOpen && (
            <ImageViewer
              src={ props.data.map((d) => d.largeImage) }
              currentIndex={ currentImage }
              disableScroll={ false }
              closeOnClickOutside={ true }
              onClose={ closeImageViewer }
            />
          )}
          </div>
        </div>
      </div>
    </div>
  );
};
