import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/team/flavio.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Dr. Flávio Arruda</h2>
              <p>{ props.data? props.data.paragraph1: "...Carregando" }</p>
              <p>{ props.data? props.data.paragraph2: "...Carregando"}</p>
              <p>{ props.data? props.data.paragraph3: "...Carregando"}</p>
                      
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
